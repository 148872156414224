import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from '../consts';

import { Li } from './styles';

const Tooltip = ({ group, value, unit }) => (
  <ul className="multi-tooltip">
    <li>
      <div className="datapoint-tooltip">
        <div className="label">
          <p>
            {value} {unit}
          </p>
        </div>
      </div>
    </li>
    <Li $color={COLORS[group]}>
      <div className="datapoint-tooltip">
        <div className="label">
          <p>Group</p>
        </div>
        <p className="value">{group}</p>
      </div>
    </Li>
  </ul>
);

Tooltip.propTypes = {
  group: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};

export default Tooltip;
