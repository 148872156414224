import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { DatePickerInput } from '@carbon/react';
import { drop } from 'ramda';
import PropTypes from 'prop-types';

import { CUSTOM, DAILY_INTERVAL } from 'store/kpis';
import { getDateRange } from 'store/kpis/utils';

import { PERIOD_OPTIONS, OPTIONS_OBJECT, getDateLabel, getDateKeyByDates } from './utils';
import { Container, Select, Button, Box, DateWrapper } from './styles';

const DateFilter = ({ name, filter, setFilter, interval, isPrevious }) => {
  const ref = useRef();
  const [isOpened, setOpened] = useState(false);
  const datesValue = useMemo(() => drop(1, filter), [filter]);
  const onSelectCurrent = useCallback(
    ({ selectedItem }) => setFilter([selectedItem.id, ...getDateRange(selectedItem.id, interval)]),
    [setFilter, interval]
  );
  const maxDate = useMemo(() => {
    const today = new Date();

    return new Date(
      today.getFullYear(),
      today.getMonth(),
      interval === DAILY_INTERVAL ? today.getDate() : 1,
      0,
      0,
      0,
      -1
    ).toISOString();
  }, [interval]);
  const onChangeCurrentDate = useCallback(
    (dates) => {
      if (dates.length === 2) setFilter([getDateKeyByDates(dates, interval), ...dates]);
    },
    [setFilter, interval]
  );
  const onToggle = useCallback(() => setOpened(($) => !$), []);

  useEffect(() => {
    if (isOpened) {
      const cb = ({ target }) => {
        if (
          !(target && target.closest(`#date_filter_collapsed_box_${name}`)) &&
          target !== ref.current &&
          !target.closest('.flatpickr-calendar')
        ) {
          setOpened(false);
        }
      };

      document.body.addEventListener('mousedown', cb);

      return () => {
        document.body.removeEventListener('mousedown', cb);
      };
    }

    return () => null;
  }, [isOpened, name]);

  return (
    <Container>
      <Select
        id={`date_filter_dropdown_${name}`}
        selectedItem={OPTIONS_OBJECT[filter[0]]}
        label=""
        titleText=""
        items={PERIOD_OPTIONS}
        itemToString={(item) => (item ? item.label : '')}
        onChange={onSelectCurrent}
      />
      {(!isPrevious || filter[0] === CUSTOM) && (
        <>
          <Button ref={ref} type="button" onClick={onToggle}>
            {getDateLabel(filter, interval)}
          </Button>
          <Box id={`date_filter_collapsed_box_${name}`} pose={isOpened ? 'open' : 'close'}>
            <DateWrapper
              id={`date_filter_date_picker_${name}`}
              datePickerType="range"
              dateFormat="d/m/Y"
              value={datesValue}
              onChange={onChangeCurrentDate}
              maxDate={maxDate}
            >
              <DatePickerInput id={`date-picker-input-id-start_${name}`} placeholder="dd/mm/yyyy" labelText="" />
              <DatePickerInput id={`date-picker-input-id-finish_${name}`} placeholder="dd/mm/yyyy" labelText="" />
            </DateWrapper>
          </Box>
        </>
      )}
    </Container>
  );
};

DateFilter.defaultProps = { isPrevious: false };
DateFilter.propTypes = {
  name: PropTypes.string.isRequired,
  filter: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired).isRequired,
  setFilter: PropTypes.func.isRequired,
  isPrevious: PropTypes.bool,
  interval: PropTypes.string.isRequired,
};

export default DateFilter;
