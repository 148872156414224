import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { SimpleBarChart } from '@carbon/charts-react';
import { Popover } from '@carbon/react';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import '@carbon/charts/styles.css';

import { GROUP, ID, VALUE } from 'store/kpis';
import { ReactComponent as CloseIcon } from 'assets/svg-icons/cross.svg';

import { COLORS } from './consts';
import Tooltip from './Tooltip';
import { Container, Head, Button, Wrapper, TitleWrap, Title, Icon, PopperContent } from './styles';

const KpiBarChart = ({ items, label, unit, onClose, description }) => {
  const [key, setKey] = useState(0);
  const options = useMemo(
    () => ({
      axes: {
        bottom: { mapsTo: GROUP, scaleType: 'labels' },
        left: { mapsTo: VALUE, title: unit.toUpperCase() },
      },
      toolbar: { enabled: false },
      tooltip: {
        customHTML: (d) => renderToString(<Tooltip group={path([0, GROUP], d)} value={path([0, VALUE], d)} unit={unit} />),
      },
      height: '100%',
      width: '100%',
      getFillColor: (group) => COLORS[group] || '#c5c5c5',
    }),
    [unit]
  );

  const [isPopper, setPopper] = useState(false);
  const onMouseEnter = useCallback(() => setPopper(true), []);
  const onMouseLeave = useCallback(() => setPopper(false), []);

  useEffect(() => {
    let frame;
    const cb = () => {
      if (frame) window.cancelAnimationFrame(frame);

      frame = window.requestAnimationFrame(() => {
        setKey(($) => $ + 1);
      });
    };

    window.addEventListener('resize', cb);

    return () => {
      if (frame) window.cancelAnimationFrame(frame);

      window.removeEventListener('resize', cb);
    };
  }, []);

  return (
    <Container>
      <Head>
        {description ? (
          <Popover open={isPopper} align="bottom-start" color="$layer-02">
            <TitleWrap>
              <Title>{label}</Title>
              <Icon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
            </TitleWrap>
            <PopperContent>{description}</PopperContent>
          </Popover>
        ) : (
          <Title>{label}</Title>
        )}
        <Button type="button" onClick={onClose} aria-label="Close Button">
          <CloseIcon />
        </Button>
      </Head>
      <Wrapper>
        <SimpleBarChart key={key} data={items} options={options} />
      </Wrapper>
    </Container>
  );
};

KpiBarChart.defaultProps = { unit: '', description: '' };
KpiBarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      [ID]: PropTypes.string.isRequired,
      [VALUE]: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default KpiBarChart;
