import { cond, equals, T } from 'ramda';

import { YTD, QTD, MTD, MONTHLY_INTERVAL } from './consts';

export const getDateRange = (key, interval) => {
  const now = new Date();
  const [year, month, date] = [now.getFullYear(), now.getMonth(), now.getDate()];

  return cond([
    [
      equals(YTD),
      () =>
        month === 0 && interval === MONTHLY_INTERVAL
          ? [new Date(year - 1, 0, 1), new Date(year, month, 1, 0, 0, 0, -1)]
          : [new Date(year, 0, 1), new Date(year, month, date, 0, 0, 0, -1)],
    ],
    [
      equals(QTD),
      () =>
        month === 0 && interval === MONTHLY_INTERVAL
          ? [new Date(year - 1, 9, 1), new Date(year, 0, 1, 0, 0, 0, -1)]
          : [new Date(year, Math.floor(month / 3) * 3, 1), new Date(year, month, date, 0, 0, 0, -1)],
    ],
    [
      equals(MTD),
      () =>
        date === 1 || interval === MONTHLY_INTERVAL
          ? [new Date(year, month - 1, 1), new Date(year, month, 1, 0, 0, 0, -1)]
          : [new Date(year, month, 1), new Date(year, month, date - 1, 0, 0, 0, -1)],
    ],
    [T, () => [new Date(year - 1, month, date - 1), new Date(year, month, date, 0, 0, 0, -1)]],
  ])(key);
};
