import React, { useMemo, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { prop } from 'ramda';

import { ID, CHART, NAME, DASHBOARD, RANGE, ITEMS, AIM, DESCRIPTION, KPIS, INTERVAL } from 'store/kpis';
import { useActions } from 'components/Root/hooks';

import Card from './Card';
import DashboardItem from './DashboardItem';
import { Container, MainTitle, App, Title } from './styles';

const KPI = () => {
  const { data: apps } = useSelector(prop('apps'));
  const { fetchApps } = useActions();
  const filteredItems = useMemo(
    () => apps.filter(({ [KPIS]: kpis, [DASHBOARD]: dashboard }) => kpis?.length || dashboard?.length),
    [apps]
  );

  useEffect(() => {
    fetchApps();
  }, [fetchApps]);

  return (
    <>
      <MainTitle>MEDIGNITION DASHBOARD</MainTitle>
      <Container>
        {filteredItems.map(({ [ID]: appId, [NAME]: name, [KPIS]: kpis, [DASHBOARD]: dashboard }) => (
          <Fragment key={appId}>
            <App>
              <Title>{name}</Title>
              <div>
                {(kpis || []).map((kpi) => (
                  <Card key={prop(ID, kpi)} id={prop(ID, kpi)} kpi={kpi} appName={name} />
                ))}
              </div>
            </App>
            {(dashboard || []).map(
              ({
                [ID]: id,
                [CHART]: chart,
                [NAME]: dashboardName,
                [RANGE]: range,
                [ITEMS]: items,
                [AIM]: aim,
                [DESCRIPTION]: description,
                [INTERVAL]: interval,
              }) => (
                <DashboardItem
                  key={id}
                  id={appId}
                  type={chart}
                  name={dashboardName}
                  range={range}
                  items={items}
                  aim={aim}
                  description={description}
                  interval={interval}
                />
              )
            )}
          </Fragment>
        ))}
      </Container>
    </>
  );
};

export default KPI;
