import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';

import { useAsyncState } from 'hooks';

import {
  ID,
  NAME,
  UNIT,
  PERIOD,
  MONTHLY,
  FILTER,
  CURR_PERIOD,
  MTD,
  QTD,
  YTD,
  LAST_12M,
  CUSTOM,
  ITEM,
  ALL_ITEM,
  INCREASE,
  DECREASE,
  LINE,
  BAR,
  useKpisActions,
  useAlerts,
} from 'store/kpis';
import { getDateRange } from 'store/kpis/utils';
import Card from './DashboardCard';

const DashboardItem = ({ id, type, name, range, items, aim, description, interval }) => {
  const [data, setData] = useAsyncState(null);
  const [filter, setFilter] = useState(() => [
    [range, ...getDateRange(range, interval)],
    [range, ...getDateRange(range, interval)],
  ]);
  const [item, setItem] = useState(ALL_ITEM);
  const { fetchDashboardKpi } = useKpisActions();
  const { action, success } = useAlerts(fetchDashboardKpi);

  useEffect(() => {
    action({ [ID]: id, [NAME]: name, [FILTER]: filter, [ITEM]: item });
  }, [action, filter, id, name, item]);

  useEffect(() => {
    if (success && !success.error) {
      setData({
        ...success,
        [UNIT]: pathOr('', [CURR_PERIOD, 0, UNIT], success),
        [PERIOD]: MONTHLY,
        commonUnit: pathOr('', [UNIT], success),
      });
    }
  }, [setData, success]);

  return (
    data && (
      <Card
        type={type}
        kpi={data}
        appName={name}
        filter={filter}
        setFilter={setFilter}
        items={items}
        item={item}
        setItem={setItem}
        aim={aim}
        description={description}
        interval={interval}
      />
    )
  );
};

DashboardItem.defaultProps = { type: LINE, range: LAST_12M, interval: '' };
DashboardItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([LINE, BAR]),
  name: PropTypes.string.isRequired,
  range: PropTypes.oneOf([MTD, QTD, YTD, LAST_12M, CUSTOM]),
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  aim: PropTypes.oneOf([INCREASE, DECREASE]).isRequired,
  description: PropTypes.string.isRequired,
  interval: PropTypes.string,
};

export default DashboardItem;
