export const FEATURE_NAME = 'kpis';

export const ID = '_id';
export const LABEL = 'label';
export const VALUE = 'value';
export const PREV_VALUE = 'prevValue';
export const DATE = 'date';
export const PERIOD = 'period';
export const ALL = 'ALL';
export const YEARLY = 'YEARLY';
export const MONTHLY = 'MONTHLY';
export const NAME = 'name';
export const UNIT = 'unit';
export const CHF = 'CHF';
export const PERCENTAGE = 'Percentage';
export const DASHBOARD = 'dashboard';
export const FILTER = 'filter';
export const APP = 'app';
export const START = 'start';
export const END = 'end';
export const PREV_START = 'prevStart';
export const PREV_END = 'prevEnd';
export const CURR_PERIOD = 'currPeriod';
export const PREV_PERIOD = 'prevPeriod';
export const MTD = 'mtd';
export const QTD = 'qtd';
export const YTD = 'ytd';
export const LAST_12M = 'last12m';
export const CUSTOM = 'custom';
export const RANGE = 'range';
export const ITEMS = 'items';
export const ITEM = 'item';
export const ALL_ITEM = 'All';
export const AIM = 'aim';
export const DESCRIPTION = 'description';
export const INCREASE = 'increase';
export const DECREASE = 'decrease';
export const CHART = 'chart';
export const LINE = 'line';
export const BAR = 'bar';
export const GROUP = 'group';
export const SUMMARY_DESCRIPTION = 'summaryDescription';
export const KPIS = 'kpis';
export const INTERVAL = 'interval';
export const DAILY_INTERVAL = 'daily';
export const MONTHLY_INTERVAL = 'monthly';
