import { MTD, QTD, YTD, LAST_12M, CUSTOM } from 'store/kpis';
import { getDateRange } from 'store/kpis/utils';

export const PERIOD_OPTIONS = [
  { id: YTD, label: 'Year to date' },
  { id: MTD, label: 'Month to date' },
  { id: QTD, label: 'Quarter to date' },
  { id: LAST_12M, label: 'Last 12 months' },
  { id: CUSTOM, label: 'Custom' },
];

export const OPTIONS_OBJECT = PERIOD_OPTIONS.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

const getFormat = (withYear, withMonth) =>
  new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    ...(withMonth && { month: 'short' }),
    ...(withYear && { year: 'numeric' }),
  });

export const getDateLabel = ([key, from, to], interval) => {
  const defRange = getDateRange(key, interval);
  const currYear = defRange[1].getFullYear();
  const start = from || defRange[0];
  const end = to || defRange[1];
  const [sY, sM] = [start.getFullYear(), start.getMonth()];
  const [eY, eM] = [end.getFullYear(), end.getMonth()];

  return [
    getFormat(sY !== currYear, !(sY === currYear && sM === eM)).format(start),
    getFormat(eY !== currYear, true).format(end),
  ].join('—');
};

export const getDateKeyByDates = (dates, interval) =>
  [MTD, QTD, YTD, LAST_12M].reduce((acc, key) => {
    if (acc) return acc;

    const range = getDateRange(key, interval);

    if (dates[0].valueOf() === range[0].valueOf() && dates[1].valueOf() === range[1].valueOf()) return key;

    return acc;
  }, null) || CUSTOM;
