import styled from 'styled-components';
import { Dropdown, Popover, PopoverContent } from '@carbon/react';

import { baseTheme } from 'theme';
import { ReactComponent as InfoIcon } from 'assets/svg-icons/info.svg';

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-bottom: 10px;
  width: 100%;
  padding: 20px;
  background: #ece9e9;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const Popper = styled(Popover)`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  width: 100%;
`;

export const PopperContent = styled(PopoverContent)`
  background: #fff;
  font-size: 13px;
  line-height: normal;
  padding: 10px 20px;
  text-align: left;
`;

export const Values = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: 100%;
  font-size: ${baseTheme.font.sizes.button};
  margin-top: 10px;
`;

export const LabelWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5em;
`;

export const Label = styled.p`
  font-size: ${baseTheme.font.sizes.button};
  text-transform: uppercase;
  letter-spacing: 1.06px;
  text-align: left;
`;

export const Icon = styled(InfoIcon)`
  height: 22px;
  width: 22px;
  min-width: 22px;
  fill: #000;
  cursor: pointer;
`;

export const Change = styled.p`
  background: ${({ isGrowing }) => (isGrowing ? '#5effb6' : '#ff5f5f')};
  font-size: ${baseTheme.font.sizes.button};
  color: ${({ isGrowing }) => (isGrowing ? '#000' : '#fff')};
  padding: 5px 10px;
  border-radius: 16px;
  margin-left: 10px;
`;

export const Panel = styled.div`
  grid-column: 1/-1;
  align-self: flex-start;
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

export const Text = styled.p`
  font-size: ${baseTheme.font.sizes.button};
  letter-spacing: 1.06px;
  white-space: nowrap;
  min-height: 40px;
  line-height: 40px;
  @media (max-width: 869px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Button = styled.button`
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: #0f62fe;
  border: none;
  outline: none;
  cursor: pointer;
  & > span {
    display: none;
  }
  @media (hover) {
    &:hover:enabled {
      background-color: #0050e6;
    }
  }
  @media (max-width: 869px) {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    & > span {
      display: inline;
      text-transform: uppercase;
      color: ${baseTheme.colors.white};
    }
  }
`;

export const Select = styled(Dropdown)`
  width: 220px;
`;
