import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { map, pipe } from 'ramda';

import { fetchApps } from 'store/apps/actions';
import { restoreSession } from 'store/session/actions';
import { fetchAlleyeProviderKPI } from 'store/alleyeDashboard/actions';
import { fetchAlleyeProviders, resetAlleyeProviders } from 'store/alleyeProviders/actions';
import { postAlleyeSales } from 'store/alleyeSales/actions';

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      map((action) => pipe(action, dispatch), {
        postAlleyeSales,
        resetAlleyeProviders,
        fetchAlleyeProviders,
        fetchAlleyeProviderKPI,
        fetchApps,
        restoreSession,
      }),
    [dispatch]
  );
};
