import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/FullScreenSpinner';
import { useSelector } from 'react-redux';

import { path } from 'ramda';
import { useActions } from '../../hooks';

const InitData = ({ children }) => {
  const user = useSelector(path(['session', 'user']));
  const isLoading = useSelector(path(['apps', 'isLoading']));
  const { fetchApps } = useActions();

  useEffect(() => {
    if (user) fetchApps();
  }, [user, fetchApps]);

  return isLoading ? <Spinner /> : children;
};

InitData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InitData;
