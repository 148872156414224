import { takeLeading, put, call, select } from 'redux-saga/effects';
import { propOr } from 'ramda';

import api from 'api';
import dictionary from 'utils/dictionary.json';

import { FETCH_APPS, FETCH_APPS_SUCCESS, FETCH_APPS_FAILED, SET_IS_LOADING_TRUE } from './consts';

function* fetchApps() {
  const { isLoading, lastFetch } = yield select(propOr({}, 'apps'));

  if (isLoading || (lastFetch && lastFetch.getTime() + 60 * 60 * 1000 > new Date())) return;

  try {
    yield put({ type: SET_IS_LOADING_TRUE });
    const response = yield call(api.fetchApps);

    if (response.error) {
      yield put({ type: FETCH_APPS_FAILED, payload: response.error });
    } else {
      yield put({ type: FETCH_APPS_SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: FETCH_APPS_FAILED, payload: dictionary.defaultError });
  }
}
export default function* main() {
  yield takeLeading(FETCH_APPS, fetchApps);
}
