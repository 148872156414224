import React, { useState, useMemo, useCallback } from 'react';
import { path, pathOr } from 'ramda';
import PropTypes from 'prop-types';

import {
  LABEL,
  VALUE,
  PREV_VALUE,
  UNIT,
  ID,
  DATE,
  NAME,
  APP,
  FILTER,
  START,
  END,
  PREV_START,
  PREV_END,
  CURR_PERIOD,
  PREV_PERIOD,
  ITEM,
  ALL_ITEM,
  INCREASE,
  DECREASE,
  LINE,
  BAR,
  GROUP,
  SUMMARY_DESCRIPTION,
  DAILY_INTERVAL,
  MONTHLY_INTERVAL,
  useKpisActions,
  useAlerts,
} from 'store/kpis';
import { useMobileDetect } from 'hooks';
import KPIValue from 'components/KPIValue';
import Portal, { KPI_HISTORY } from 'components/Portal';
import KpiLineChart from 'components/KpiLineChart';
import KpiBarChart from 'components/KpiBarChart';
import { ReactComponent as DownloadIcon } from 'assets/svg-icons/documentDownload.svg';

import Accordion from './Accordion';
import DateFilter from './DateFilter';
import { Container, Popper, Values, LabelWrap, Label, Icon, Change, Panel, Text, Button, Select, PopperContent } from './styles';

const DashboardCard = ({ type, kpi, appName, filter, setFilter, items, item, setItem, aim, description, interval }) => {
  const [isOpened, setOpened] = useState(false);
  const [isPopper, setPopper] = useState(false);
  const isMobile = useMobileDetect(870);
  const values = useMemo(
    () =>
      type === BAR
        ? kpi[CURR_PERIOD].map(({ [GROUP]: group, [VALUE]: value }, i) => ({
            [ID]: String(i),
            [GROUP]: group,
            [VALUE]: value,
          }))
        : kpi[CURR_PERIOD].map(({ [DATE]: date, [VALUE]: value }, i) => ({
            [ID]: String(i),
            [DATE]: date,
            [VALUE]: value,
            [PREV_VALUE]: path([PREV_PERIOD, i, VALUE], kpi),
          })),
    [type, kpi]
  );
  const growth = useMemo(() => {
    if (kpi[UNIT] === 'percentage') return kpi[VALUE] - kpi[PREV_VALUE];

    return kpi[PREV_VALUE] > 0 ? (kpi[VALUE] - kpi[PREV_VALUE]) / kpi[PREV_VALUE] : null;
  }, [kpi]);
  const hasChange = kpi[PREV_VALUE] > 0;

  const handleClick = useCallback(() => setOpened(true), []);
  const onClose = useCallback(() => setOpened(false), []);
  const chartInterval = useMemo(
    () =>
      (interval === DAILY_INTERVAL &&
        (pathOr(0, [0, 2], filter).valueOf() - pathOr(0, [0, 1], filter).valueOf()) / 86400000 >= 100 &&
        MONTHLY_INTERVAL) ||
      interval,
    [filter, interval]
  );

  const { exportDashboardExcel } = useKpisActions();
  const { action, loading } = useAlerts(exportDashboardExcel);
  const exportExcel = useCallback(
    () => action({ [ID]: kpi[APP], [NAME]: kpi[NAME], [FILTER]: filter, [ITEM]: item }),
    [action, kpi, filter, item]
  );

  const onSetCurrent = useCallback((value) => setFilter(($) => [value, $[1]]), [setFilter]);
  const onSetPrevious = useCallback((value) => setFilter(($) => [$[0], value]), [setFilter]);
  const dropDownItems = useMemo(() => (items.length ? [ALL_ITEM, ...items] : items), [items]);
  const onSelectItem = useCallback(({ selectedItem }) => setItem(selectedItem), [setItem]);
  const onMouseEnter = useCallback(() => setPopper(true), []);
  const onMouseLeave = useCallback(() => setPopper(false), []);

  const HistoryMenu = isMobile ? Accordion : Panel;

  return (
    <>
      {isOpened && (
        <Portal type={KPI_HISTORY}>
          {type === BAR ? (
            <KpiBarChart items={values} label={kpi[LABEL]} unit={kpi[UNIT]} description={description} onClose={onClose} />
          ) : (
            <KpiLineChart
              appName={appName}
              appId={kpi[APP]}
              dashboardName={kpi[NAME]}
              items={values}
              label={kpi[LABEL]}
              unit={kpi[UNIT]}
              period={path([0, 0], filter)}
              description={description}
              onClose={onClose}
              interval={chartInterval}
              isDashboard
            >
              <HistoryMenu title="Settings">
                <DateFilter name={`${kpi[NAME]}_curr`} filter={filter[0]} setFilter={onSetCurrent} interval={interval} />
                <Text>compared to</Text>
                <DateFilter
                  name={`${kpi[NAME]}_prev`}
                  filter={filter[1]}
                  setFilter={onSetPrevious}
                  interval={interval}
                  isPrevious
                />
                {items.length > 0 && (
                  <Select
                    id={`dashboard_card_dropdown_${kpi[NAME]}`}
                    selectedItem={item}
                    label="Product"
                    titleText="Product"
                    items={dropDownItems}
                    onChange={onSelectItem}
                  />
                )}
                <Button type="button" onClick={exportExcel} disabled={loading}>
                  <span>Download</span>
                  <DownloadIcon />
                </Button>
              </HistoryMenu>
            </KpiLineChart>
          )}
        </Portal>
      )}
      <Container type="button" onClick={handleClick}>
        <Popper open={isPopper} align="top-start" color="$layer-02">
          <LabelWrap>
            <Label>{kpi[LABEL]}</Label>
            <Icon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
          </LabelWrap>
          {hasChange && (
            <Change isGrowing={aim === DECREASE ? growth <= 0 : growth >= 0}>{`${growth >= 0 ? '+' : ''}${Math.round(
              100 * growth
            )}%`}</Change>
          )}
          <PopperContent>{(type === BAR && kpi[SUMMARY_DESCRIPTION]) || description}</PopperContent>
        </Popper>
        <Values>
          <KPIValue
            start={kpi[START]}
            end={kpi[END]}
            period={path([0, 0], filter)}
            unit={kpi.commonUnit || kpi[UNIT]}
            value={kpi[VALUE]}
          />
          {hasChange && (
            <KPIValue
              start={kpi[PREV_START]}
              end={kpi[PREV_END]}
              period={path([1, 0], filter)}
              unit={kpi[UNIT]}
              value={kpi[PREV_VALUE]}
              prev
            />
          )}
        </Values>
      </Container>
    </>
  );
};

DashboardCard.propTypes = {
  type: PropTypes.oneOf([LINE, BAR]).isRequired,
  kpi: PropTypes.shape({
    [LABEL]: PropTypes.string.isRequired,
    [VALUE]: PropTypes.number.isRequired,
    [UNIT]: PropTypes.string.isRequired,
    [START]: PropTypes.string,
    [END]: PropTypes.string,
    [PREV_VALUE]: PropTypes.number,
    [PREV_START]: PropTypes.string,
    [PREV_END]: PropTypes.string,
    [CURR_PERIOD]: PropTypes.arrayOf(
      PropTypes.shape({
        [DATE]: PropTypes.string,
        [VALUE]: PropTypes.number.isRequired,
        [GROUP]: PropTypes.string,
      }).isRequired
    ).isRequired,
    [PREV_PERIOD]: PropTypes.arrayOf(
      PropTypes.shape({
        [DATE]: PropTypes.string.isRequired,
        [VALUE]: PropTypes.number.isRequired,
      }).isRequired
    ),
    [APP]: PropTypes.string.isRequired,
    [NAME]: PropTypes.string.isRequired,
    [SUMMARY_DESCRIPTION]: PropTypes.string,
    commonUnit: PropTypes.string.isRequired,
  }).isRequired,
  appName: PropTypes.string.isRequired,
  filter: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired).isRequired
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  item: PropTypes.string.isRequired,
  setItem: PropTypes.func.isRequired,
  aim: PropTypes.oneOf([INCREASE, DECREASE]).isRequired,
  description: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
};

export default DashboardCard;
