import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from 'assets/svg-icons/Arrow';
import ArrowIconGray from 'assets/svg-icons/ArrowGray';
import { DateText, Arrow } from './styles';

const DatePeriod = ({ start, end, prev }) => {
  const getDate = useCallback((date) => {
    const d = new Date(date);

    return d.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      ...(d.getFullYear() !== new Date().getFullYear() && { year: 'numeric' }),
    });
  }, []);

  return (
    <DateText>
      <span>{getDate(start)}</span>
      <Arrow>{prev ? <ArrowIconGray /> : <ArrowIcon />}</Arrow>
      <span>{getDate(end)}</span>
    </DateText>
  );
};

DatePeriod.defaultProps = {
  prev: false,
};

DatePeriod.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  prev: PropTypes.bool,
};

export default DatePeriod;
