import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { prop } from 'ramda';
import { useSelector } from 'react-redux';

import Dropdown from 'components/Dropdown';
import { useActions } from 'components/Root/hooks';

import { useFilteredCompanies } from './hooks';
import AppsTable from './AppsTable';
import { StatusString, Title } from './styles';

const Apps = () => {
  const [filterId, setFilterId] = useState('');
  const { data: apps, updateTime } = useSelector(prop('apps'));
  const { fetchApps } = useActions();
  const companies = useFilteredCompanies();

  useEffect(() => {
    fetchApps();
  }, [fetchApps]);

  const systemStatusUpdate = useMemo(
    () =>
      new Date(updateTime).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }),
    [updateTime]
  );

  const filteredApp = useCallback(
    (appsList) => (filterId ? appsList.filter(({ company = '' }) => company.includes(filterId)) : appsList),
    [filterId]
  );

  return (
    <>
      <Title>MEDIGNITION TECHNOLOGY</Title>
      <StatusString>{`System Status as of ${systemStatusUpdate}`}</StatusString>
      <Dropdown
        withBottomMargin
        placeholder="select a brand"
        list={companies}
        getOptionId={prop('id')}
        getOptionLabel={prop('companyName')}
        selectedOption={filterId}
        onSelect={setFilterId}
      />
      <AppsTable apps={filteredApp(apps)} />
    </>
  );
};

export default Apps;
