export const FORMAT_DATE = new Intl.DateTimeFormat('de-CH', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

export const MONTH_FORMAT_DATE = new Intl.DateTimeFormat('de-CH', {
  year: 'numeric',
  month: 'short',
});
